<template>
	<s-crud
		no-border
		title="Túneles"
		:config="this.config"
		@save="save($event)"
		add
		edit
		restore
		@rowSelected="rowSelected($event)"
		remove
		:filter="filter"
		height="auto"
		no-full
	>
		<template scope="props">
			<v-container
				style="padding-top: 0px"
				v-if="props.item != null"
				class="pb-0"
			>
				<v-row style="margin: auto" justify="center">
					<v-col>
						<s-text
							v-model="props.item.FtmDescription"
							label="Descripción"
						/>
					</v-col>
					<v-col>
						<s-text
							v-model="props.item.FtmCapacity"
							label="Capacidad (Nº Palets)"
							number
							:min="1"
						/>
					</v-col>
					<v-col>
						<s-text
							v-model="props.item.FtmCapacityTon"
							label="Capacidad (TON)"
							decimal
							:min="1"
						/>
					</v-col>
					<!-- <v-col cols="4">
						<s-select
							:items="itemsStateTunnel"
							item-value="MtsID"
							item-text="MtsDescription"
							label="Estado"
							v-model="props.item.MtsID"
						>
						</s-select>
						
					</v-col> -->
				</v-row>
			</v-container>
		</template>
	</s-crud>
</template>

<script>
	import _service from "@/services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';

	export default {
		data: () => ({
			ftmID: 0,
			ftmDescription: "",
			filter: {},
			config: {
				title: "Túneles de Maduración",
				service: _service,
				model: {
					FtmID: "ID",
				},
				headers: [
					{
						text: "ID",
						value: "FtmID",
						width: "5%",
						align: "end",
						align: "center",
						sorteable: true,
					},
					{
						text: "Descripción",
						value: "FtmDescription",
						width: "55%",
						sorteable: true,
					},
					/* {
						text: "Estado",
						value: "MtsDescription",
						width: "55%",
						sorteable: true,
					}, */
					{
						text: "Capacidad (Pallet)",
						value: "FtmCapacity",
						align: "center",
						width: "15%",
						sorteable: false,
					},
					{
						text: "Capacidad (Ton)",
						value: "FtmCapacityTon",
						align: "center",
						width: "15%",
						sorteable: false,
					},
				],
			},

			itemsStateTunnel: []
		}),

		created() 
		{
			this.initialize()
		},

		methods: {

			initialize(){
				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data
					}
				})
			},
			rowSelected(items) {
				console.log(items);
			},

			save(item) {
				if (item.FtmDescription.length == 0) {
					this.$fun.alert("Ingrese Descripción", "warning");
					return;
				}
				if (item.FtmCapacity <= 0) {
					this.$fun.alert("Ingrese capacidad de Tunel", "warning");
					return;
				}

				/* if (item.MtsID == null) {
					this.$fun.alert("Seleccione estado", "warning");
					return;
				} */
				item.MtsID = 1;
				item.SecStatus = 1;
				this.UsrCreateID = this.$fun.getUserID();

				console.log(item);

				item.save();
			},
		},
	};
</script>
